import React from "react";
import { GatsbyImage, getImage } from "gatsby-plugin-image";

import { PinkButton } from "../components/StyledButton";
import { Link } from "gatsby";
import PlaceholderFeaturedImage from "../components/PlaceholderFeaturedImage"


const BlogListItem = ({
  data,
  featuredImage,
  dateInfo,
  blogTitle,
  blogUrl,
  blogExcerpt,
  slug,
}) => {
  const gatsbyImage =
    featuredImage === undefined || featuredImage === null || featuredImage.node === null
      ? null
      : getImage(featuredImage.node.localFile);

  const alt =
    featuredImage === undefined ||
    featuredImage === null ||
    featuredImage.node === null ||
    featuredImage.node.altText === undefined ||
    featuredImage.node.altText === null
      ? String("Angela Cooney Blog post")
      : String(featuredImage.node.altText);

{/**  let excerptXML = new DOMParser().parseFromString(blogExcerpt, "text/html");

  let containsReadMore = excerptXML.getElementsByClassName("read-more");
  if (containsReadMore.length > 0) {
    containsReadMore[0].remove();
  }

  blogExcerpt = excerptXML.body.innerHTML  */}
  blogUrl = '/blog/'+blogUrl
  return (
    <div className="grid grid-cols-1 gap-6 md:grid-cols-4">
      {gatsbyImage ? (
        <GatsbyImage
          image={gatsbyImage}
          className="object-cover w-full h-auto col-span-1 bg-center "
          alt={alt}
          loading="lazy"
          placeholder="blurred"
        />
      ) : (
        <PlaceholderFeaturedImage alt={alt} />
      )}
      <div className="col-span-1 md:col-span-3">
        <p className="mb-2 -mt-1 text-sm font-normal text-gray-500">{dateInfo}</p>
        <h2 className="mb-2 text-xl font-extrabold leading-snug text-gray-800">
          <Link to={blogUrl} className="text-teal-400 hover:text-purple-700">
            {blogTitle}
          </Link>
        </h2>
        <div
          className="mb-3 font-normal text-gray-500"
          dangerouslySetInnerHTML={{ __html: blogExcerpt }}
        />
        <Link to={blogUrl} >
          <PinkButton>Read More</PinkButton>
        </Link>
      </div>
    </div>
  );
};

export default BlogListItem;
