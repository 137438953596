import React from "react";
import { Link, withPrefix, graphql } from "gatsby";
import Layout from "../components/layout";

import SEO from "../components/seo";
import {LatestBlogList} from "../components/BlogList";

import BackgroundImage from "gatsby-background-image";
import BlogPageBannerWrapper from "../components/BlogPageBannerWrapper";


const Blog = ({ data }) => {
  const blogPosts = data.allWpPost.nodes;
  const imageData = data.banner.childImageSharp.fluid;
  return (
    <Layout
      headerClass="bg-white relative"
      bodyClass="px-0 md:px-0 lg:px-0 min-h-screen"
    >
      <SEO title="Angela Cooney - Executive Coach - Blog" />
      <BlogPageBannerWrapper image={imageData}>
      <LatestBlogList blogPosts={blogPosts} />
        </BlogPageBannerWrapper>
    </Layout>
  );
};

export const query = graphql`
  query {
    allWpPost(sort: { fields: [date], order: DESC }) {
      nodes {
        id
        title
        excerpt
        slug
        date(formatString: "DD MMMM YYYY")
        featuredImage {
          node {
            localFile {
              childImageSharp {
                gatsbyImageData(
                  width: 230
                  placeholder: BLURRED
                  formats: [AVIF, WEBP, JPG]
                )
              }
            }
          }
        }
      }
    }
    banner: file(
      relativePath: { eq: "blog-executive-coach-angela-cooney-banner.jpg" }
    ) {
      childImageSharp {
        fluid(quality: 90, maxWidth: 1920) {
          ...GatsbyImageSharpFluid_withWebp
        }
      }
    }
  }
`;

export default Blog;
