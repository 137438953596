import React from "react";

const BlogListHeading = ({headingText}) => {
  return (
    <div className="flex flex-col m-3 text-4xl ">
      <h2 className="self-center font-heading text-white">{headingText}</h2>
    </div>
  );
};

export default BlogListHeading