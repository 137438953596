import React from "react"
import BlogListHeading from "./BlogListHeading";

import BlogListItem from "./BlogListItem"

const BlogList = ({ blogPosts, blogHeadingText }) => {
    return (
      <>
       <BlogListHeading headingText={blogHeadingText} />
        <div className="w-full xl:w-5/6 p-6 flex flex-col space-y-16">
          {blogPosts.map((node) => (
            <BlogListItem
              blogTitle={node.title}
              dateInfo={node.date}
              blogExcerpt={node.excerpt}
              featuredImage={node.featuredImage}
              blogUrl={node.slug}
            />
          ))}
        </div>
      </>
    );
  };

export const LatestBlogList = ({ blogPosts }) => {
    return (
        <div className="container mx-auto px-6 mt-6 md:px-10 lg:px-16 pb-4 bg-purple-900 heropattern-texture-teal-900 flex flex-col items-center">
        <BlogList blogPosts={blogPosts} blogHeadingText="Latest Posts" />
        </div>
    )
}
  

export default BlogList